.dashboard-page-container {

  position: relative;
  z-index: 1;
  // &::after {
  //   content: "";
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   background-color: rgba(0, 0, 0, 0.8);
  //   position: absolute;
  //   z-index: -1;
  //   backdrop-filter: blur(8px);
  // }
}
.dash-table-header {
  color: var(--brand-color-1);
  text-transform: capitalize;
  margin-bottom: 10px;
}
