.not-found-container {

    .not-found-inner {
        display: flex;
        justify-content: center;
        height: calc(100vh - var(--header-height) - var(--header-top));
        align-items: center;

        img {
            width: 400px;
            object-fit: contain;
        }
    }
}