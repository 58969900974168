.landing-accordion-section{
    padding-top: 100px;
    // background-image: url('https://i0.wp.com/biofriendlyplanet.com/wp-content/uploads/2021/11/enviro-friendly-apartment-3.jpg?fit=1280%2C853&ssl=1');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    padding-top: 100px;
    color: #fff;
    background-attachment: fixed;

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
    }
    .section-heading{
        text-align: center;
    }
    .section-sub-heading{
        text-align: center;
    }
    .landing-accordion-inner{
        width: 70%;
        margin: auto;
        @media (max-width:768px) {
            width: 100%;
            
        }
        .accordion{
            background-color: transparent;
            .accordion-item{
                background-color: transparent;
                .accordion-header{
                    background-color: transparent;
                    color: #fff;
                    button{
                        color: #fff;
                        background-color: transparent;


                    }
                    .accordion-button:not(.collapsed){
                        color: #fff;
                        background-color: var(--brand-color-1);

                    }
                    .accordion-button::after{
                        background-image: url('../../Assets/Images/Layout/down.png');
                    }
                }
            }
            .accordion-body{
                background-color: transparent;
                color: #fff;
            }
        }
    }

}