.my-layouty-body {
  display: flex;
  flex-direction: column;
  margin-left: 200px;
  background-color: #141b21;
  transition: all 202ms ease-in-out;
  padding: 20px;
  padding-top: 90px;
  position: relative;
  z-index: 2;
  display: flex;
  position: fixed;
  font-family: var(--roboto) !important;
  width: calc(100vw - 200px);

  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }

  @media (max-width: 768px) {
    padding: 10px;
    padding-top: 90px;
  }
}

.my-layout-collapsed {
  margin-left: 100px;
  width: calc(100vw - 100px);
}

.my-layout-collapsed-tablet {
  margin-left: 0px;
  width: calc(100vw - 0px);
}

.my-layout-right {
  width: 20%;
}

.my-right-collapsed {}

.np-admin-main-button {
  border: 2px var(--brand-color-1) solid;
  background-color: transparent;
  padding: 3px 12px;
  border-radius: 20px;
  text-transform: capitalize;
  cursor: pointer;
  color: #fff;
}

.add-button {
  margin-bottom: 10px;
}

.image-click-display-container {
  display: flex;

  .image-display-on-click {
    height: 100px;
    width: 100px;
    display: flex;
  }

  .image-click-box {
    height: 100px;
    width: 100px;
    border: 1px solid #fff;
    font-size: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    margin-right: 20px;
    color: #fff;
  }
}

.mobile-collapsed-drop {
  position: fixed;
  height: 100vh;
  width: 100vw;
  left: 0;
  top: 0;
  z-index: 3;
  display: none;
  background-color: rgba(0, 0, 0, 0.5);

  @media (max-width: 992px) {
    display: block;
  }
}


.repeater-container {
  border: 1px solid var(--brand-color-1);
  border-radius: 20px;
  padding: 10px;
  margin: 20px 0;

  .repeater-top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 15px;

    .repeater-title {
      color: #fff;

    }

    .repeater-button {
      display: flex;
      justify-content: flex-end;
      cursor: pointer;
      transition: all 500ms ease;

      &:hover {
        opacity: 0.8;
      }

      span {
        display: inline-flex;
        height: 20px;
        width: 20px;
        font-size: 13px;
        justify-content: center;
        align-items: center;
        background-color: var(--brand-color-1);
        border-radius: 50%;
        color: #fff;
      }
    }

  }

  .repeater-children {

    .repeater-inner {
      position: relative;
      border: 1px solid var(--brand-color-1);
      border-radius: 20px;
      background-color: var(--brand-color-1);
      margin-bottom: 15px;
      padding: 10px;
    }

    .repeater-delete {
      position: absolute;
      right: 10px;
      top: 10px;
      color: red;
      font-size: 17px;
      cursor: pointer;
    }
  }

}

.amenities-icon-container {
  
  margin: 25px 0;

  .amenities-title {
    color: #fff;
    margin-bottom: 10px;
  }
  .amenities-icon-inner{
    display: flex;
    column-gap: 15px;
    row-gap: 20px;
    flex-wrap: wrap;
  }

  .amenities-icon {
    height: 40px;
    width: 40px;
    border: 1px solid var(--brand-color-1);
    border-radius: 50%;
    color: #fff;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover{
      background-color: var(--brand-color-1);
    }
  }
  .amenities-icon-selected{
    background-color: var(--brand-color-1)!important;
  }
}