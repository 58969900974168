.reviews-slide-item {
    width: 100%;

    .review-top {
        display: flex;
        align-items: center;
        column-gap: 15px;

        .user-image {
            border: 1px solid var(--brand-color-1);
            padding: 4px;
            border-radius: 50%;

            img {
                border-radius: 50%;
                width: 40px;
                height: 40px;
                object-fit: cover;
            }
        }

        .user-name-top {
            display: flex;
            flex-direction: column;

            .review-name {
                font-size: 16px;
                font-weight: bold;
                margin-bottom: -3px;
            }
        }

    }

    .review-text {
        margin-top: 20px;
        font-size: 14px;
    }

    .review-rating {}

}