.LoginWrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: white;
}
.LoginBox .ant-form-item-explain ant-form-item-explain-error {
  color: white;
}

.LoginBox h2 {
  padding: 0;
  margin: 0;
  color: white;
}
.LoginBox label.ant-form-item-required {
  color: white;
}
label.ant-checkbox-wrapper span {
  color: white;
}

.buttonReverse {
  background: black;
  color: black;
  border: medium none;
  border: 1px black solid;
  padding: 10px 15px;
  cursor: pointer;
  height: auto;
  width: auto;
  font-weight: bold;
  font-size: 16px;
}
.buttonReverse:hover {
  color: black;
  background: rgb(246, 183, 69);
}

.LoginBox {
  border-radius: 20px;
  box-shadow: 3px 3px 5px black;
  padding: 50px 20px;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: red;
  display: flex;
  flex-direction: column !important;
}
.LoginBox .ant-checkbox-checked .ant-checkbox-inner {
  background-color: black;
  border-color: black;
}
.LoginBox .ant-checkbox-inner:hover {
  border-color: black;
}
.admin-login {
  border: 2px solid var(--brand-color-1);
  padding: 60px 40px;
  border-radius: 10px;
  /* box-shadow: 1px 1px 0px 1px grey; */
  .buttons {
    button {
      width: 100%;
    }
  }
}
.admin-login .joinButton {
  width: auto !important;
}
.input-section {
  background: #000;
  width: 100%;
  margin: auto;
}
.inputSection input {
  background: transparent !important;
  border: none !important;
  border-bottom: 1px solid var(--brand-color-1) !important;
  color: white;
}
.inputSection input:focus-visible {
  border: none !important;
  outline: none !important;
  border-bottom: 1px solid var(--brand-color-1) !important;
}

.login-into {
  color: white;
  text-align: center;
  font-size: 28px;
  margin-bottom: 30px;
}
.forget-password {
  color: var(--brand-color-1);
  text-decoration: none;
  margin-bottom: 10px;
}
