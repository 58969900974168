.image-popup-container {
  display: flex;
  justify-content: space-between;
 flex-wrap: wrap;
  .image-selection {
    margin-bottom: 30px;
    width: 35%;
    margin-right: 5%;
    @media (max-width: 992px) {
      width: 100%;
      margin-right: 0;
    }
  }
  .image-right {
   
    width: 60%;
    @media (max-width: 992px) {
      width: 100%;
      margin-right: 0;
      margin-top: 50px;
    }
    .image-display {
      display: flex;
      flex-wrap: wrap;
      height: 80vh;
      overflow-y: scroll;
      row-gap: 20px;
      column-gap: 10px;
      .image-display-box {
        height: 70px;
        width: 70px;
        position: relative;
        @media (max-width: 992px) {
          width: 50px;
          height: 50px;
        }
        .image-display-box-overlay {
          height: 100%;
          width: 100%;
          position: absolute;
          top: 0;
          left: 0;
          background-color: #ff00008a;
          cursor: pointer;
        }
        
        img {
          height: 100%;
          width: 100%;
          object-fit: cover;
          cursor: pointer;
        }
      }
    }
  }
  .image-popup-button-container {
    display: flex;
    justify-content: flex-end;
    height: 50px;

    button{
      color: #000;
    }
  }
}
