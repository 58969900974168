.landing-about-section {
    padding-top: 80px;
    background-color: #f3f7fb;

}

.landing-about-inner {
    display: flex;
    column-gap: 5%;
    flex-wrap: wrap;
    row-gap: 20px;

    @media (max-width:768px) {
        flex-direction: column-reverse;
        row-gap: 20px;
    }

    .landing-about-text {
        flex-basis: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: flex-start;

        @media (max-width:768px) {
            flex-basis: 100%;
        }

        .section-heading {}

        .section-sub-heading {}
    }

    .landing-about-image {
        flex-basis: 50%;

        @media (max-width:768px) {
            flex-basis: 100%;

            img {
                height: 300px;
                object-fit: cover;
            }

        }

        img {
            width: 100%;
            height: 300px;
            object-fit: cover;
        }
    }

}