.apartments-page{
    padding: 60px 0 100px;
    .section-heading{
        text-align: center;
    }
    .section-sub-heading{
        text-align: center;
    }
}

.apartment-page-inner{
    display: flex;
    row-gap: 40px;
    column-gap: 2%;
    flex-wrap: wrap;
}