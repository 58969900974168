* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Jost', sans-serif !important;
  /* font-family: 'Roboto', sans-serif!important; */
  /* font-family: 'Poppins', sans-serif!important; */

  font-weight: 300;

}

:root {
  --header-height: 80px;
  --brand-color-1: #1366b0;
  /* --brand-color: #1366b0; */

  --header-top: 30px
}

h1,
h2,
h3,
h4,
h5 {
  color: inherit !important;
}

section {
  padding: 0px 0 130px;
}

.section-heading {
  color: var(--brand-color-1) !important;
  font-size: 14px;
  font-weight: 400;
  text-transform: uppercase;
  margin-bottom: 8px;
}

.section-sub-heading {
  font-size: 22px;
  font-weight: bold;
  text-transform: uppercase;
  margin-bottom: 30px;

}


.main-button {
  background-color: var(--brand-color-1);
  color: #fff;
  padding: 10px 24px;
  border: 1px solid var(--brand-color-1);
  transition: all 500ms ease;
  border-radius: 20px;
  font-size: 14px;
  /* letter-spacing: 1.1px; */
  text-decoration: none;
  display: inline-flex;
}

.alternate-button {
  background-color: #fff;
  color: var(--brand-color-1);
  padding: 10px 24px;
  border: 1px solid var(--brand-color-1);
  transition: all 500ms ease;
  border-radius: 20px;
  font-size: 14px;
  /* letter-spacing: 1.1px; */
}

.ant-upload-list {
  overflow-y: scroll!important;
  max-height: 40px!important;
}