.my-layout-content {
  padding: 0 20px 20px;
  position: relative;
  z-index: 2;
  display: flex;
  height: calc(100vh - 80px);
  @media (max-width: 992px) {
    padding: 0 0 20px;
  }
  .main-body {
    width: 70%;
    position: relative;
    z-index: 2;
    background-image: url("../../../Assets/Images/Brand/logo.png");
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    font-family: var(--roboto)!important;

    @media (max-width: 992px) {
      width: 100%;
    }
    &::after {
      content: "";
      position: absolute;
      z-index: -1;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(20, 27, 33, 0.5);
      backdrop-filter: blur(8px);
    }
    .main-body-inner {
      height: calc(100vh - 60px);
      overflow-y: scroll;
      padding: 0 30px 100px;
    }
  }
  table {
    border-collapse: separate;
    border-spacing: 0 5px;
  }
  .ant-table {
    background: transparent !important;
    color: #fff;
    th {
      border: none !important;
    }
    td {
      border: none !important;
      background: rgba(0, 0, 0, 0.3) !important;
      margin-bottom: 10px;
      backdrop-filter: blur(3px);
    }
    tbody {
      border-collapse: separate;
      border-spacing: 0 10px;
    }

    tr:first-child td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    tr:first-child td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    tr:last-child td:first-child {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    tr:last-child td:last-child {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }

    tr:first-child td {
      border-top-style: solid;
    }
    tr td:first-child {
      border-left-style: solid;
    }
    .ant-table-thead > tr > th {
      background: transparent !important;
      color: #fff;
    }
    .ant-table-tbody {
      // background: rgba(0, 0, 0, 0.5) !important;
      color: #fff;
    }
    .ant-table-tbody > tr.ant-table-row:hover > td,
    .ant-table-tbody > tr > td.ant-table-cell-row-hover {
      background: rgba(0, 0, 0, 0.6) !important;
    }
    .ant-table-row {
      background: transparent !important;
      margin-bottom: 3px;
    }
  }
}

.top-edit-sheet {
  position: absolute;
  top: 0;
  left: 0;
  z-index: 1;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  transition: all 500ms ease-in-out;
  padding: 30px 0 100px;
  height: calc(100vh - 80px);
  overflow-y: scroll;
  backdrop-filter: blur(3px);
  .top-edit-inner {
    padding: 0 40px;
  }
  .close-top-sheet {
    position: absolute;
    top: 20px;
    right: 30px;
    color: red;
    font-size: 30px;
    cursor: pointer;
  }
}
