.landing-hero-section {
    height: calc(100vh - var(--header-height) - var(--header-top));
    padding: 0 0;
    // background-image: url('https://i0.wp.com/biofriendlyplanet.com/wp-content/uploads/2021/11/enviro-friendly-apartment-3.jpg?fit=1280%2C853&ssl=1');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    // padding-top: 100px;
    color: #fff;
    // background-attachment: fixed;

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.7);
        position: absolute;
    }

    .banner-slider {
        height: calc(100vh - var(--header-height));

        img {
            width: 100%;
            height: calc(100vh - var(--header-height));
            object-fit: cover;
        }

    }

    .landing-hero-text {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);

        @media (max-width:768px) {
            transform: translate(-50%, -50%);
            left: 50%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            width: 100%;
        }

        .hero-text-heading {
            font-size: 48px;
            margin-bottom: 6px;

            @media (max-width:768px) {
                font-size: 26px;
                text-align: center;
            }
        }

        .hero-detail {
            font-size: 16px;

            @media (max-width:768px) {

                font-size: 14px;
                text-align: center;
            }
        }

        .main-button {}
    }
}