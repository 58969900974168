.right-col-container {
  width: 30%;
  height: 100%;
  padding: 0px 20px;
  height: calc(100vh - 80px);
  overflow-y: hidden;
  @media (max-width: 992px) {
    width: 0%;
    display: none;
  }
  .right-col-contact {
    height: 300px;
    backdrop-filter: blur(8px);
    background-color: rgba(0, 0, 0, 0.4);
    border-radius: 20px;
    padding: 10px 20px;
    overflow-y: scroll;
    .right-col-contact-header {
      color: var(--brand-color-1);
      text-transform: capitalize;
      margin-bottom: 10px;
    }
    .right-contact-list {
      color: #fff;
      margin-bottom: 20px;

      .right-contact-name {
        font-size: 12px;
        text-transform: capitalize;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
        font-weight: bold;
      }
      .right-contact-message {
        font-size: 12px;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
      }
    }
  }
}
