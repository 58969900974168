.apartment-slide-item {
border-radius: 10px;
overflow: hidden;
flex-basis: 30%;
// background-color: var(--brand-color-1);
box-shadow: 0px 0px 6px 0px #ccc;
background-color: #fff;
    img {
        width: 100%;
        object-fit: cover;
        height: 200px;
    }
    .appartment-desc-sec {
        padding: 15px 15px;

        .appartment-name {
            font-size: 18px;
            font-weight: 600;
            margin-bottom: -3px;
        }

        .appartment-rating {}

        .apartment-desc {
            margin-top: 10px;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
            overflow: hidden;
            font-size: 14px;
        }
    }

 
}