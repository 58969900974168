.header-container {
    margin-bottom: 3px;
    box-shadow: 1px 1px 1px 1px #ccc;
}

.header-inner {
    display: flex;
    justify-content: space-between;
    height: var(--header-height);
    align-items: center;
}

.header-logo {
    display: flex;
    align-items: center;
    align-items: center;
    position: relative;
    height: 100%;
    width: 300px;
    @media (max-width: 768px) {
        width: 200px;
        
    }
    a {
        font-size: 32px;
        font-weight: bold;
        @media (max-width: 768px) {
            font-size: 22px;

        }
        img {
            width: 250px;
            position: absolute;
            top: 50%;
            left: -45px;
            transform: translateY(-50%);
            object-fit: contain;
            height: var(--header-height);
            @media (max-width: 768px) {
                width: 180px;
                left: -11px;
            }
        }
    }

}

.header-top {
    background-color: var(--brand-color-1);
    height: var(--header-top);
    display: flex;
    align-items: center;

    .header-top-inner {
        display: flex;
        justify-content: space-between;

        .header-top-left {}

        .header-top-right {}
    }
}