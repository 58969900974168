.admin-input-field {
  margin-bottom: 20px;

  input {
    height: 40px;
    border: 1px solid #fff;
    border-radius: 30px;
    outline: none;
    padding: 20px 20px;
    width: 100%;
    font-family: var(--roboto)!important;

  }

  .input-label {
    margin-bottom: 5px;
    font-family: var(--roboto)!important;
    color: #fff;
  }

  textarea {
    border: 1px solid #fff;
    border-radius: 30px;
    outline: none;
    padding: 20px 20px;
    width: 100%;
    font-family: var(--roboto)!important;
  }
}
