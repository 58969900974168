.footer-section {
    font-size: 15px;
    padding: 0;
    // background-image: url('https://i0.wp.com/biofriendlyplanet.com/wp-content/uploads/2021/11/enviro-friendly-apartment-3.jpg?fit=1280%2C853&ssl=1');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    color: #fff;
    background-attachment: fixed;
    background-color: var(--brand-color-1);

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        // background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
    }

    .footer-inner {
        padding: 50px 0;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        row-gap: 20px;

        .footer-about {
            flex-basis: 30%;

            @media (max-width:768px) {
                flex-basis: 100%;

            }
a{
    color: #fff;
    font-size: 34px;
    @media (max-width:768px) {
        font-size: 22px;

    }
}
            img {
                width: 250px;
                margin-left: -30px;
                height: 100px;
                object-fit: contain;
            }
        }

        .footer-text {
            margin-top: 10px;
            font-weight: 300;
        }

        .footer-heading {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .footer-visit {
            flex-basis: 20%;

            @media (max-width:768px) {
                flex-basis: 100%;

            }
        }

        .footer-links-container {
            flex-basis: 15%;

            @media (max-width:768px) {
                flex-basis: 100%;

            }

            .footer-links {
                display: flex;
                flex-direction: column;
                row-gap: 10px;

                .footer-link {
                    color: inherit;
                    text-decoration: none;
                    font-weight: 300;

                }
            }
        }

        .footer-contact {
            flex-basis: 25%;

            @media (max-width:768px) {
                flex-basis: 100%;

            }

            .footer-contact-list {
                display: flex;
                flex-direction: column;
                row-gap: 7px;
                margin-bottom: 20px;
                font-weight: 300;
                row-gap: 10px;


                .footer-contact-item {
                    span{
                        font-size: 13px;
                        margin-right: 3px;
                    }
                }
            }
        }
    }

    .social-icon {
        color: #fff;
    }

}

.social-media-container {
    display: flex;
    column-gap: 15px;
    color: #fff;
    text-decoration: none;

    .social-icon {
        text-decoration: none;
        color: #fff;
        font-size: 19px;
        text-decoration: none;
    }
}

.footer-bottom {
    background-color: #063351;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
a{
    transition: all 500ms ease;
    color: #69b4fa;
}
    a:hover{
        transform: scale(1.1);
        font-weight: bold;
    }
}