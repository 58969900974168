.nav-container {
    display: flex;
    height: 100%;
    align-items: center;
    column-gap: 25px;

    @media (max-width: 768px) {
        display: none;
    }

    .nav-list {
        text-decoration: none;

        a {
            text-decoration: none;
            color: #000;
        }
    }
}

.nav-mobile {
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: -100;
    transition: all 500ms ease;
    opacity: 0;
    left: 0;
    top: 0;
    background-color: var(--brand-color-1);
    padding-top: 50px;
    padding-left: 10px;
    row-gap: 15px;

    @media (max-width: 768px) {
        display: flex;
        flex-direction: column;
    }

    .nav-mobile-cross {
        position: absolute;
        top: 20px;
        right: 20px;
        color: #fff;
        z-index: 88;
        font-size: 20px;
    }

    .nav-list {
        text-decoration: none;

        a {
            text-decoration: none;
            color: #fff;

            &:hover {
                text-decoration: underline;
            }
        }
    }

}

.nav-burger {
    display: none;
font-size: 18px;
    @media (max-width: 768px) {
        display: inline-block;

    }
}

.nav-mobile-on {
    z-index: 57;
    opacity: 1;
}


.nav-active {
    a {
        color: var(--brand-color-1) !important;
    }
}
.nav-active-mobile {
    a {
        color: black!important;
    }
}
.nan-is:hover{
    color: var(--brand-color-1)!important;
}