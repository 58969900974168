.contact-info {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background-image: url('https://i0.wp.com/biofriendlyplanet.com/wp-content/uploads/2021/11/enviro-friendly-apartment-3.jpg?fit=1280%2C853&ssl=1');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    padding-top: 100px;
    color: #fff;
    background-attachment: fixed;
padding-bottom: 100px;
    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        background-color: rgba(0, 0, 0, 0.9);
        position: absolute;
    }
    @media (max-width:768px) {
        flex-basis: 100%;
    }

    .contact-page-logo {
        margin-bottom: 15px;
        padding: 10px;
        border: 1px solid var(--brand-color-1);
        border-radius: 50%;
        height: 130px;
        width: 130px;

        img {
            width: 100%;
            height: 100%;
            object-fit: contain;
        }

    }

    .contact-email-heading {
        font-size: 22px;
    }

    .contact-contact-email {
        margin-top: -5px;
    }

    .contact-else {}

    .social-media-container {
        margin-top: -8px;

        .social-icon {
            color: var(--brand-color-1);
        }
    }
}