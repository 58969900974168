.landing-apartments-section {
    // background-image: url('https://i.pinimg.com/originals/19/62/f3/1962f3c1bfebf4c6a5478db070c89426.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    z-index: 1;
    position: relative;
    padding-top: 100px;
    padding-bottom: 100px;
    background-attachment: fixed;

    &::after {
        content: "";
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: -1;
        // background-color: rgba(24, 148, 223, 0.5);
        position: absolute;
    }

    .section-heading {
        text-align: center;

    }

    .section-sub-heading {
        text-align: center;

    }

    .landing-apartments-inner {
        display: flex;
        column-gap: 3%;
        margin-top: 30px;
        flex-wrap: wrap;
        row-gap: 30px;

        .slick-slide {
            margin-left: 20px;
        }

        .slick-list [data-slick-index="0"] {
            margin-left: 0;
        }
    }

    .appartment-button-container {
        margin-top: 30px;
        display: flex;
        justify-content: center;
    }
}