.apartment-inner {
    padding: 30px 0 60px;

    .apartment-heading-section {
        margin-bottom: 20px;

        .apartment-heading-inner {
            .apartment-heading-top {
                .apartment-title {}

                .apartment-sub-title {
                    display: flex;
                    column-gap: 20px;
                    align-items: center;

                    .apartment-address {
                        color: grey;
                        font-size: 14px;

                        span {
                            font-size: 12px;
                            margin-right: 5px;
                        }
                    }

                    .apartment-rating {}
                }

            }

        }

    }

    .apartment-images-section {
        margin-bottom: 30px;

        .apartment-images-inner {


            .ap-images-top {
                display: flex;
                column-gap: 30px;
                flex-wrap: wrap;
                row-gap: 20px;

                .slick-dots {
                    display: flex !important;
                    column-gap: 5px;
                    bottom: -84px;
                    position: static;
                    row-gap: 10px;
                    column-gap: 10px;
                    flex-wrap: wrap;
                    // justify-content: space-between;
                    margin-top: 15px;
                    flex-grow: 1;


                    li {
                        width: 100px;
                        height: 60px;
                        @media (max-width: 992px) {
                            width: 80px;
                            height: 40px;
                            
                        }

                        .slick-dot-anchor {
                            display: flex;
                            width: 100%;
                            height: 100%;

                            .slick-dot-image {
                                width: 100%;
                                height: 100%;
                                object-fit: cover;
                            }

                            .slick-dot-shade {
                                display: none;
                                position: absolute;
                                top: 0;
                                left: 0;
                                height: 100%;
                                width: 100%;
                                background-color: #1366b066;
                                z-index: 2;
                            }
                        }
                    }

                    .slick-active {
                        .slick-dot-shade {
                            display: block !important;
                        }
                    }
                }

                .ap-images-slide {
                    // padding-bottom: 100px;
                    width: calc(70% - 30px);

                    @media (max-width: 992px) {
                        width: 100%;

                    }

                    .ap-slider {
                        width: 100%;
                    }

                    .ap-images-con {
                        width: 100%;

                        img {
                            width: 100%;
                            height: 415px;
                            object-fit: cover;
                            @media (max-width: 768px) {
                                height: 350px;
                                
                            }
                        }
                    }
                }

                img {
                    width: calc(70% - 30px);

                    @media (max-width: 992px) {
                        width: 100%;

                    }
                }

                .book-box {
                    padding: 10px 10px;
                    border: 1px solid grey;
                    width: 30%;
                    height: 300px;
                    z-index: 55;
                    background-color: #fff;
                    display: flex;
                    flex-direction: column;
                    border-radius: 10px;
                    justify-content: center;
                    // transition: all 500ms ease;

                    @media (max-width: 992px) {
                        position: static;
                        width: 100%;


                    }

                    .ap-type {
                        margin-bottom: 4px;
                        color: grey;
                    }

                    .ap-price {
                        font-size: 22px;
                        margin-bottom: 10px;
                        font-weight: 500;
                    }
                    .ap-price-list{
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        &:not(:last-child){
                            margin-bottom: 5px;
                            font-size: 15px;
                        }
                        .price-heading{
                            font-size: 15px;
                        }
                        .price-text{

                        }
                    }

                    .ap-highlights {
                        margin-bottom: 20px;

                        .ap-highlights-heading {
                            font-size: 18px;
                            margin-bottom: 4px;
                        }

                        .ap-highlights-item {
                            display: flex;
                            align-items: center;
                            font-size: 14px;
                            padding-left: 6px;

                            span {
                                color: grey;
                                margin-right: 8px;
                                font-size: 12px;
                            }
                        }

                    }

                    .main-button {
                        text-align: center;
                        display: flex;
                        justify-content: center;
                        margin-top: 20px;

                    }

                }
            }

            .facilities-collection {
                display: flex;
                flex-wrap: wrap;
                margin-top: 20px;
                column-gap: 10px;
                row-gap: 15px;
                width: 70%;

                @media (max-width:992px) {
                    width: 100%;

                }
            }

        }


    }
.apartment-list{
    margin-bottom: 25px;
    .apartment-list-inner{
        display: flex;
        flex-direction: row;
        row-gap: 6px;
        justify-content: space-between;
        flex-wrap: wrap;
        margin-top: 15px;
        width: 70%;
        @media (max-width: 992px) {
            width: 100%;
            
        }

        .ap-list-item{
            width: 50%;
            font-size: 15px;
            @media (max-width: 992px) {
                width: 100%;
                
            }
            .ap-item-icon{
                color: var(--brand-color-1);
                margin-right: 7px;
                font-size: 12px;
            }
            .ap-item-text{

            }

        }
    }
}
.ap-distances{

    .about-distances {
        margin-top: 50px;
        width: 70%;
        @media (max-width: 992px) {
            width: 100%;
        }

        .distances-list-container {
            width: 100%;

            .distance-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .distance-text {

                    width: 180px;
                }

                .distance-line {
                    border-bottom: 1px dashed #DDD;
                    height: 1px;
                    flex-grow: 1;
                    margin: 0 15px
                }

                .distance-d {
                    width: 70px;
                    font-size: 15px;
                }
            }
        }
    }
}
    .apartment-desc {
        .apartment-desc-inner {
            width: 70%;
            text-align: justify;

            @media (max-width:768px) {
                width: 100%;

            }
        }
    }

    .apartment-map {
        margin-top: 30px;

        .apartment-map-inner {}
    }

}

.book-box-sticky {
    position: fixed;
    right: 50px;
    top: 0;
    width: 300px !important;
    height: 300px !important;
    background: white;

    @media (max-width: 992px) {
        position: static;
        width: 100% !important;

    }
    @media (max-width: 1024) {
       right: 100px;

    }
}



.reservation-modal{
    width: 50%!important;
    border-radius: 20px;
    overflow: hidden!important;
    @media (max-width: 992px) {
        width: 70%!important;
        
    }
    @media (max-width: 768px) {
        width: 100%!important;
        
    }
    .reservation-modal-container{

    }
    .reservation-modal-image{
        display: flex;
        justify-content: center;
        img{
            width: 200px;
            height: 200px;
            object-fit: contain;
            max-width: 100%;
            border: 1px solid var(--brand-color-1);
            padding: 5px;
            border-radius: 50%;
        }
    }
    .reservation-modal-title{
        margin-top: 8px;
text-align: center;
    }

    .form-label {
        font-size: 14px;
        margin-bottom: 2px;
    }

    .form-control {
        font-size: 14px;
    }

    .text-muted.form-text {
        font-size: 12px;
    }

    .main-button {
        margin-top: 15px;
    }
}