.apartment-page-item-container {
    display: flex;
    flex-direction: column;
    border: 1px solid var(--brand-color-1);
    border-radius: 10px;
    overflow: hidden;
    flex-basis: 31%;
    flex-grow: 1;

    @media (max-width: 850px) {
        flex-basis: 100%;

    }

    .ap-image {
        flex-basis: 50%;

        img {
            width: 100%;
            height: 200px;
            object-fit: cover;
        }
    }

    .ap-desc {
        flex-basis: 45%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 15px 0;
    }

    .ap-top {
        padding: 0 15px;

        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        column-gap: 2%;

        .ap-top-left {
            flex-basis: 68%;

            .ap-name {
                font-size: 18px;
                font-weight: bold;
                margin-bottom: 3px;
                display: -webkit-box;
                -webkit-line-clamp: 2;
                -webkit-box-orient: vertical;
                overflow: hidden;
            }

            .ap-location {
                font-size: 13px;
                color: grey;

                span {
                    font-size: 12px;
                    margin-right: 4px;
                }
            }
        }

        .ap-rating {}
    }

    .ap-middle {
        margin: 10px 0;
        padding: 6px 15px;

        background-color: var(--brand-color-1);
        color: #fff;

        span {
            color: green;
            margin-right: 4px;
        }
    }

    .ap-bottom {
        padding: 0 15px;

        display: flex;
        justify-content: flex-end;
        align-items: center;

        .ap-price {
            font-size: 22px;
            font-weight: 500;
            @media (max-width: 850px) {
                font-size: 16px;
                font-weight: 700;
            }
        }

        .ap-availability {
            font-size: 13px;
            color: grey;
        }

    }

    .ap-below {
        padding: 0 15px;
        margin-top: 10px;
    }

}