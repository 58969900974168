.contact-page {
    padding: 40px 0 0px;

    .contact-inner {
        display: flex;
        column-gap: 5%;
        flex-wrap: wrap;
        row-gap: 30px;
        padding-bottom: 40px;

        @media (max-width:768px) {}

        .contact-page-left {
            flex-basis: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            @media (max-width:768px) {
                flex-basis: 100%;
            }

            .contact-page-logo {
                margin-bottom: 15px;
                padding: 10px;
                border: 1px solid var(--brand-color-1);
                border-radius: 50%;
                height: 130px;
                width: 130px;

                img {
                    width: 100%;
                    height: 100%;
                    object-fit: contain;
                }

            }

            .contact-email-heading {
                font-size: 22px;
            }

            .contact-contact-email {
                margin-top: -5px;
            }

            .contact-else {}

            .social-media-container {
                margin-top: -8px;

                .social-icon {
                    color: var(--brand-color-1);
                }
            }
        }

        .contact-page-right {
            flex-basis: 50%;
            padding: 30px 0;

            @media (max-width:768px) {
                flex-basis: 100%;
            }

            .contact-right-heading {
                font-size: 22px;
                margin-bottom: 20px;
            }

            .form-label {
                font-size: 14px;
                margin-bottom: 2px;
            }

            .form-control {
                font-size: 14px;
            }

            .text-muted.form-text {
                font-size: 12px;
            }

            .main-button {
                margin-top: 15px;
            }
        }
    }
}




.social-contact-container{
    margin-bottom: 20px;
    .social-contact-item{
        display: flex;
        align-items: center;
        row-gap: 10px;
        .social-contact-name{
            width: 80px;
        }
        .social-contact-val{
            margin-left: 15px;
        }

    }

}