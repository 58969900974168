.about-page {
    padding: 40px 0 0px;

    .about-page-inner {
        display: flex;
        column-gap: 5%;
        row-gap: 30px;
        flex-wrap: wrap;

        @media (max-width:768px) {
            flex-direction: column-reverse;

        }

        .about-page-texts {
            flex-basis: 45%;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;

            @media (max-width:768px) {
                flex-basis: 100%;


            }

            .about-details {}

        }

        .about-page-image {
            flex-basis: 50%;

            img {
                width: 100%;
            }

            @media (max-width:768px) {
                flex-basis: 100%;

                img {
                    max-height: 300px;
                    object-fit: cover;
                }

            }
        }

    }

    .about-medium {
        display: flex;
        column-gap: 5%;
        padding-bottom: 70px;
        margin-top: 40px;
        flex-wrap: wrap;
    }

    .about-amenities {
        margin-top: 50px;
        flex-basis: 60%;
        @media (max-width: 768px) {
            flex-basis: 100%;
        }
        .section-heading {}

        .amenities-list-container {
            width: 100%;
            display: flex;
            flex-wrap: wrap;
            column-gap: 4%;


            .amenities-list-item {
                display: flex;
                align-items: center;
                font-size: 15px;
                padding: 7px 0;
                flex-basis: 48%;
                @media (max-width: 768px) {
                    flex-basis: 100%;
                    
                }

                &:not(:last-child) {
                    border-bottom: 1px solid #DDD;
                }

                span {
                    margin-right: 8px;
                    font-size: 13px;
                    color: grey;
                }
            }
        }
    }

    .about-distances {
        margin-top: 50px;
        flex-basis: 35%;
        @media (max-width: 768px) {
            flex-basis: 100%;
        }

        .distances-list-container {
            width: 100%;

            .distance-list-item {
                display: flex;
                justify-content: space-between;
                align-items: center;

                &:not(:last-child) {
                    margin-bottom: 10px;
                }

                .distance-text {

                    width: 190px;
                }

                .distance-line {
                    border-bottom: 1px dashed #DDD;
                    height: 1px;
                    flex-grow: 1;
                    margin: 0 20px
                }

                .distance-d {
                    width: 70px;
                    font-size: 15px;
                }
            }
        }
    }

    .about-bottom {

    }

}

.about-map {}