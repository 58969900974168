.landing-reviews-section {
    padding-top: 100px;
    background-color: #f3f7fb;

    .landing-reviews-inner {
        column-gap: 5%;
        display: flex;
        row-gap: 30px;
        flex-wrap: wrap;

        .reviews-certified {
            flex-basis: 50%;

            img {
                width: 100%;
            }

            @media (max-width: 768px) {
                flex-basis: 100%;

            }
        }

        .review-slider-outer {
            flex-basis: 45%;
            width: 45%;
            display: flex;
            align-items: center;

            .slick-dots {
                bottom: -45px !important;
            }

            @media (max-width: 768px) {
                flex-basis: 100%;

            }

            .review-slider-inner {
                width: 100%;

                .reviews-slider {
                    flex-basis: 45%;
                }
            }
        }
    }
}