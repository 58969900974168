.my-layout-header {
  height: 80px;
  background-color: #141b21;
  width: 100%;
  display: flex;
  align-items: center;
  padding: 20px 20px 20px 0;
  justify-content: space-between;
  z-index: 3;
  position: fixed;
  top: 0;
  margin-left: 200px;
  width: calc(100vw - 200px);
  right: 0;
  @media (max-width: 992px) {
    width: 100%;
    margin-left: 0;
  }
}
.my-layout-collapsed {
  .my-layout-header {
    margin-left: 100px;
    width: calc(100vw - 100px);
  }
}
.my-layout-collapsed-tablet {
  .my-layout-header {
    margin-left: 0px;
    width: calc(100vw - 0px);
    padding-left: 20px;
  }
}
.my-header-right {
  display: flex;
}
.my-header-left {
  color: #fff;
}
.header-icon {
  cursor: pointer;
  color: #fff;
}

.header-icon:not(:last-child) {
  margin-right: 20px;
}
